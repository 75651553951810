import React, { useEffect, useState } from "react";
import { ClockProps } from "./Clock.types";

const Clock: React.FC<ClockProps> = ({ timeZone }) => {
	const [hours, setHours] = useState("");
	const [minutes, setMinutes] = useState("");
	const [seconds, setSeconds] = useState("");

	useEffect(() => {
		const interval = setInterval(() => {
			const now = new Date();
			const opts: Intl.DateTimeFormatOptions = {
				timeZone,
				hour: "2-digit",
				minute: "2-digit",
				second: "2-digit",
				hour12: false,
			};
			const localeTime = new Intl.DateTimeFormat("en-US", opts).format(
				now,
			);
			const [hrs, mins, secs] = localeTime.split(":");
			setHours(hrs);
			setMinutes(mins);
			setSeconds(secs);
		}, 1000);

		return () => clearInterval(interval);
	}, [timeZone]);

	if (hours && minutes && seconds) {
		return (
			<div className="flex gap-2 justify-center text-9xl font-semibold items-center leading-3">
				<div className={"w-36 text-center"}>{hours}</div>:
				<div className={"w-36 text-center"}>{minutes}</div>:
				<div className={"w-36 text-center"}>{seconds}</div>
			</div>
		);
	} else {
		return null;
	}
};

export default Clock;
