import React from "react";
import Clock from "./components/Clock";
import { isValidTimeZone } from "./utils/utils";

const App: React.FC = () => {
	const urlParams = new URLSearchParams(window.location.search);
	let timeZone = urlParams.get("tz") || "UTC";

	// Check if the provided timeZone is valid, otherwise default to UTC
	if (!isValidTimeZone(timeZone)) {
		timeZone = "UTC";
	}

	return (
		<div className="min-h-screen flex items-center justify-center w-full">
			<Clock timeZone={timeZone} />
		</div>
	);
};

export default App;
